import React from "react";
import { Link } from "gatsby";

import { rhythm, scale } from "../utils/typography";

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link style={{fontSize: `1.5rem`, textDecoration: `none`}} to={props.to}>{props.children}</Link>
  </li>
);

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header = (
      <header style={{ margin: `1.0rem` }}>
        <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
          <span style={{ display: `inline`, fontSize: `1.5rem` }}>{title}</span>
        </Link>
        {/* <ul style={{ listStyle: `none`, float: `right` }}>
          <ListLink to="/">About</ListLink>
          <ListLink to="/projects">Projects</ListLink>
          <ListLink to="/blog/">Blog</ListLink>
        </ul> */}
      </header>
    );
  
    return (
      <div>
        <header>{header}</header>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
          }}
        >
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()} Vitor Gomes, built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </div>
      </div>
    );
  }
}

export default Layout;
